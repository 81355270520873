import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7652e315"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RPHSearchBlock: require('/usr/src/app/src/components/v2/templates/RPHSearchBlock/index.vue').default,RPHAd: require('/usr/src/app/src/components/v2/molecules/RPHAd/index.vue').default,RPHNearestProductsBlock: require('/usr/src/app/src/components/v2/templates/RPHNearestProductsBlock/index.vue').default,RPHArticlesBlock: require('/usr/src/app/src/components/v2/templates/RPHArticlesBlock/index.vue').default,RPHAboutBlock: require('/usr/src/app/src/components/v2/templates/RPHAboutBlock/index.vue').default})
